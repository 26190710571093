/* eslint-disable quote-props */

/*
 * Any updates here must also be changes in 2 other places:
 * - the CSS palette definition: packages/pulse/src/styles/pulse/_color-palette-utilities.scss
 * - the TailwindCSS config: packages/pulse/src/config/tailwind.config.js
 */

export const UI_PALETTE = {
  transparent: 'transparent',

  'base-backdrop': 'var(--base-backdrop)',
  'base-module': 'var(--base-module)',
  'base-module-subtle': 'var(--base-module-subtle)',
  'base-editor': 'var(--base-editor)',
  'base-modal-blanket': 'var(--base-modal-blanket)',

  'text-default': 'var(--text-default)',
  'text-muted': 'var(--text-muted)',
  'text-disabled': 'var(--text-disabled)',
  'text-link': 'var(--text-link)',
  'text-error': 'var(--text-error)',
  'text-accent': 'var(--text-accent)',
  'text-success': 'var(--text-success)',

  'text-on-neutral': 'var(--text-on-neutral)',
  'text-on-accent': 'var(--text-on-accent)',
  'text-on-beta': 'var(--text-on-beta)',
  'text-on-success': 'var(--text-on-success)',
  'text-on-error': 'var(--text-on-error)',
  'text-on-notice': 'var(--text-on-notice)',
  'text-on-paywall': 'var(--text-on-paywall)',

  'neutral-container': 'var(--neutral-container)',
  'neutral-container-emphasis': 'var(--neutral-container-emphasis)',
  'neutral-border': 'var(--neutral-border)',
  'neutral-border-emphasis': 'var(--neutral-border-emphasis)',
  'neutral-fill': 'var(--neutral-fill)',
  'neutral-fill-emphasis': 'var(--neutral-fill-emphasis)',

  'accent-container': 'var(--accent-container)',
  'accent-container-emphasis': 'var(--accent-container-emphasis)',
  'accent-border': 'var(--accent-border)',
  'accent-border-emphasis': 'var(--accent-border-emphasis)',
  'accent-fill': 'var(--accent-fill)',
  'accent-fill-emphasis': 'var(--accent-fill-emphasis)',

  'beta-container': 'var(--beta-container)',
  'beta-container-emphasis': 'var(--beta-container-emphasis)',
  'beta-border': 'var(--beta-border)',
  'beta-border-emphasis': 'var(--beta-border-emphasis)',
  'beta-fill': 'var(--beta-fill)',
  'beta-fill-emphasis': 'var(--beta-fill-emphasis)',

  'success-container': 'var(--success-container)',
  'success-container-emphasis': 'var(--success-container-emphasis)',
  'success-border': 'var(--success-border)',
  'success-border-emphasis': 'var(--success-border-emphasis)',
  'success-fill': 'var(--success-fill)',
  'success-fill-emphasis': 'var(--success-fill-emphasis)',

  'error-container': 'var(--error-container)',
  'error-container-emphasis': 'var(--error-container-emphasis)',
  'error-border': 'var(--error-border)',
  'error-border-emphasis': 'var(--error-border-emphasis)',
  'error-fill': 'var(--error-fill)',
  'error-fill-emphasis': 'var(--error-fill-emphasis)',

  'notice-container': 'var(--notice-container)',
  'notice-container-emphasis': 'var(--notice-container-emphasis)',
  'notice-border': 'var(--notice-border)',
  'notice-border-emphasis': 'var(--notice-border-emphasis)',
  'notice-fill': 'var(--notice-fill)',
  'notice-fill-emphasis': 'var(--notice-fill-emphasis)',

  'paywall-container': 'var(--paywall-container)',
  'paywall-container-emphasis': 'var(--paywall-container-emphasis)',
  'paywall-border': 'var(--paywall-border)',
  'paywall-border-emphasis': 'var(--paywall-border-emphasis)',
  'paywall-fill': 'var(--paywall-fill)',
  'paywall-fill-emphasis': 'var(--paywall-fill-emphasis)',

  'ai-gradient-step-1': 'var(--ai-gradient-step-1)',
  'ai-gradient-step-2': 'var(--ai-gradient-step-2)',
  'ai-gradient-step-3': 'var(--ai-gradient-step-3)',
  'ai-gradient-step-4': 'var(--ai-gradient-step-4)',

  // TODO(@22a): remove these after 2025 Visual Redesign Release 1
  'border-shadow-dark': 'rgba(0, 0, 0, 0.3)',
  'border-shadow': 'rgba(0, 0, 0, 0.15)',
  'border-shadow-light': 'rgba(0, 0, 0, 0.05)',
} as const;

export const AVATAR_PALETTE = {
  'avatar-red': 'var(--avatar-red)',
  'avatar-orange': 'var(--avatar-orange)',
  'avatar-yellow': 'var(--avatar-yellow)',
  'avatar-green': 'var(--avatar-green)',
  'avatar-purple': 'var(--avatar-purple)',
  'avatar-blue': 'var(--avatar-blue)',
  'avatar-teal': 'var(--avatar-teal)',
  'avatar-pink': 'var(--avatar-pink)',
} as const;

export const TICKET_AVATAR_COLOR = 'var(--avatar-ticket)';

export const VISUALIZATION_PALETTE = {
  'vis-yellow-20': 'var(--vis-yellow-20)',
  'vis-yellow-30': 'var(--vis-yellow-30)',
  'vis-yellow-40': 'var(--vis-yellow-40)',
  'vis-yellow-50': 'var(--vis-yellow-50)',
  'vis-yellow-60': 'var(--vis-yellow-60)',
  'vis-yellow-70': 'var(--vis-yellow-70)',
  'vis-yellow-80': 'var(--vis-yellow-80)',
  'vis-orange-20': 'var(--vis-orange-20)',
  'vis-orange-30': 'var(--vis-orange-30)',
  'vis-orange-40': 'var(--vis-orange-40)',
  'vis-orange-50': 'var(--vis-orange-50)',
  'vis-orange-60': 'var(--vis-orange-60)',
  'vis-orange-70': 'var(--vis-orange-70)',
  'vis-orange-80': 'var(--vis-orange-80)',
  'vis-salmon-20': 'var(--vis-salmon-20)',
  'vis-salmon-30': 'var(--vis-salmon-30)',
  'vis-salmon-40': 'var(--vis-salmon-40)',
  'vis-salmon-50': 'var(--vis-salmon-50)',
  'vis-salmon-60': 'var(--vis-salmon-60)',
  'vis-salmon-70': 'var(--vis-salmon-70)',
  'vis-salmon-80': 'var(--vis-salmon-80)',
  'vis-pink-20': 'var(--vis-pink-20)',
  'vis-pink-30': 'var(--vis-pink-30)',
  'vis-pink-40': 'var(--vis-pink-40)',
  'vis-pink-50': 'var(--vis-pink-50)',
  'vis-pink-60': 'var(--vis-pink-60)',
  'vis-pink-70': 'var(--vis-pink-70)',
  'vis-pink-80': 'var(--vis-pink-80)',
  'vis-magenta-20': 'var(--vis-magenta-20)',
  'vis-magenta-30': 'var(--vis-magenta-30)',
  'vis-magenta-40': 'var(--vis-magenta-40)',
  'vis-magenta-50': 'var(--vis-magenta-50)',
  'vis-magenta-60': 'var(--vis-magenta-60)',
  'vis-magenta-70': 'var(--vis-magenta-70)',
  'vis-magenta-80': 'var(--vis-magenta-80)',
  'vis-violet-20': 'var(--vis-violet-20)',
  'vis-violet-30': 'var(--vis-violet-30)',
  'vis-violet-40': 'var(--vis-violet-40)',
  'vis-violet-50': 'var(--vis-violet-50)',
  'vis-violet-60': 'var(--vis-violet-60)',
  'vis-violet-70': 'var(--vis-violet-70)',
  'vis-violet-80': 'var(--vis-violet-80)',
  'vis-slateblue-20': 'var(--vis-slateblue-20)',
  'vis-slateblue-30': 'var(--vis-slateblue-30)',
  'vis-slateblue-40': 'var(--vis-slateblue-40)',
  'vis-slateblue-50': 'var(--vis-slateblue-50)',
  'vis-slateblue-60': 'var(--vis-slateblue-60)',
  'vis-slateblue-70': 'var(--vis-slateblue-70)',
  'vis-slateblue-80': 'var(--vis-slateblue-80)',
  'vis-blue-20': 'var(--vis-blue-20)',
  'vis-blue-30': 'var(--vis-blue-30)',
  'vis-blue-40': 'var(--vis-blue-40)',
  'vis-blue-50': 'var(--vis-blue-50)',
  'vis-blue-60': 'var(--vis-blue-60)',
  'vis-blue-70': 'var(--vis-blue-70)',
  'vis-blue-80': 'var(--vis-blue-80)',
  'vis-azure-20': 'var(--vis-azure-20)',
  'vis-azure-30': 'var(--vis-azure-30)',
  'vis-azure-40': 'var(--vis-azure-40)',
  'vis-azure-50': 'var(--vis-azure-50)',
  'vis-azure-60': 'var(--vis-azure-60)',
  'vis-azure-70': 'var(--vis-azure-70)',
  'vis-azure-80': 'var(--vis-azure-80)',
  'vis-cyan-20': 'var(--vis-cyan-20)',
  'vis-cyan-30': 'var(--vis-cyan-30)',
  'vis-cyan-40': 'var(--vis-cyan-40)',
  'vis-cyan-50': 'var(--vis-cyan-50)',
  'vis-cyan-60': 'var(--vis-cyan-60)',
  'vis-cyan-70': 'var(--vis-cyan-70)',
  'vis-cyan-80': 'var(--vis-cyan-80)',
  'vis-turquoise-20': 'var(--vis-turquoise-20)',
  'vis-turquoise-30': 'var(--vis-turquoise-30)',
  'vis-turquoise-40': 'var(--vis-turquoise-40)',
  'vis-turquoise-50': 'var(--vis-turquoise-50)',
  'vis-turquoise-60': 'var(--vis-turquoise-60)',
  'vis-turquoise-70': 'var(--vis-turquoise-70)',
  'vis-turquoise-80': 'var(--vis-turquoise-80)',
  'vis-teal-20': 'var(--vis-teal-20)',
  'vis-teal-30': 'var(--vis-teal-30)',
  'vis-teal-40': 'var(--vis-teal-40)',
  'vis-teal-50': 'var(--vis-teal-50)',
  'vis-teal-60': 'var(--vis-teal-60)',
  'vis-teal-70': 'var(--vis-teal-70)',
  'vis-teal-80': 'var(--vis-teal-80)',
  'vis-mint-20': 'var(--vis-mint-20)',
  'vis-mint-30': 'var(--vis-mint-30)',
  'vis-mint-40': 'var(--vis-mint-40)',
  'vis-mint-50': 'var(--vis-mint-50)',
  'vis-mint-60': 'var(--vis-mint-60)',
  'vis-mint-70': 'var(--vis-mint-70)',
  'vis-mint-80': 'var(--vis-mint-80)',
  'vis-green-20': 'var(--vis-green-20)',
  'vis-green-30': 'var(--vis-green-30)',
  'vis-green-40': 'var(--vis-green-40)',
  'vis-green-50': 'var(--vis-green-50)',
  'vis-green-60': 'var(--vis-green-60)',
  'vis-green-70': 'var(--vis-green-70)',
  'vis-green-80': 'var(--vis-green-80)',
  'vis-mantis-20': 'var(--vis-mantis-20)',
  'vis-mantis-30': 'var(--vis-mantis-30)',
  'vis-mantis-40': 'var(--vis-mantis-40)',
  'vis-mantis-50': 'var(--vis-mantis-50)',
  'vis-mantis-60': 'var(--vis-mantis-60)',
  'vis-mantis-70': 'var(--vis-mantis-70)',
  'vis-mantis-80': 'var(--vis-mantis-80)',
  'vis-olive-20': 'var(--vis-olive-20)',
  'vis-olive-30': 'var(--vis-olive-30)',
  'vis-olive-40': 'var(--vis-olive-40)',
  'vis-olive-50': 'var(--vis-olive-50)',
  'vis-olive-60': 'var(--vis-olive-60)',
  'vis-olive-70': 'var(--vis-olive-70)',
  'vis-olive-80': 'var(--vis-olive-80)',
  'vis-mustard-20': 'var(--vis-mustard-20)',
  'vis-mustard-30': 'var(--vis-mustard-30)',
  'vis-mustard-40': 'var(--vis-mustard-40)',
  'vis-mustard-50': 'var(--vis-mustard-50)',
  'vis-mustard-60': 'var(--vis-mustard-60)',
  'vis-mustard-70': 'var(--vis-mustard-70)',
  'vis-mustard-80': 'var(--vis-mustard-80)',
} as const;

const PALETTE = {
  ...UI_PALETTE,
  ...VISUALIZATION_PALETTE,
  ...AVATAR_PALETTE,
} as const;

export const PALETTE_COLOR_NAMES = Object.keys(PALETTE);

export default PALETTE;
