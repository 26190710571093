import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { HELP_CONTENT_EXPERIMENT_FF_NAME } from '@intercom/pulse/lib/temp-constants';

export default class PulseHelpContentLink extends Component {
  @service appService;
  @service intercomEventService;

  linkStyling = this.args.linkStyling || 'standalone';

  get isDisabledByExperimentalFeatureFlag() {
    return (
      this.linkStyling === 'standalone' &&
      this.appService?.app?.canUseFeature(HELP_CONTENT_EXPERIMENT_FF_NAME)
    );
  }

  @action
  handleClick(event) {
    event.preventDefault();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_content_link',
      context: this.linkStyling,
      content_type: 'checklist',
      link_to: this.args.checklistId,
    });
    if (typeof Intercom !== 'undefined') {
      Intercom('startChecklist', this.args.checklistId);
    }
  }
}
