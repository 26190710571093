import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';

export default class ExpandingInput extends Component {
  get isEmpty() {
    return isEmpty(this.args.value);
  }

  get classes() {
    if (!this.args.isUnstyled) {
      let classes =
        'p-0 border-b outline-none truncate transition duration-200 ease-in-out bg-unset';

      if (!this.args.isReadOnly) {
        classes += `
          hover:border-neutral-border hover:border-dashed
          focus:border-neutral-border-emphasis focus:border-dashed
        `;
      }

      if (this.args.hasError && !this.args.isReadOnly) {
        classes += ' border-solid border-error-border';
      } else {
        classes += ' border-transparent border-dashed';
      }

      return classes;
    }
    return '';
  }

  @action
  resize(element) {
    let elementType = element.type;

    if (this.isEmpty) {
      // temporarily set the element's value to the placeholder value
      // so that the element will be wide enough to display it
      // clear the element's type attribute, so the placeholder can contain
      // a value that's not actually valid
      // (e.g. type='number' placeholder='123...')
      element.type = '';
      element.value = element.placeholder;
    }

    element.style.width = 0;

    let scrollWidth = element.scrollWidth;
    if (UserAgentDetector.isEdge()) {
      // Non-ideal quick fix for https://github.com/intercom/intercom/issues/138542 in Microsoft Edge.
      scrollWidth = 200;
    }

    // we need 1 pixel extra 'padding' for the cursor - see https://github.com/intercom/intercom/issues/35333
    element.style.width = `${scrollWidth + 1}px`;

    if (this.isEmpty) {
      element.type = elementType;
      element.value = '';
    }
  }

  @action
  autoFocusAndSelect(element) {
    if (this.args.autoFocus || this.args.autoSelect) {
      element.focus();

      if (this.args.autoSelect) {
        element.select();
      }
    }
  }

  @action
  onFocusIn() {
    if (this.args.onFocusIn) {
      this.args.onFocusIn();
    }
  }

  @action
  onEnter(event) {
    if (this.args.blurOnEnterKey) {
      event.target.blur();
    }
  }

  @action
  onFocusOut() {
    if (this.args.onFocusOut) {
      this.args.onFocusOut();
    }
  }
}
