import Component from '@glimmer/component';
import { assert } from '@ember/debug';

const STAMP_COLOR_MAP = {
  blue: {
    background: 'bg-beta-container',
    border: 'border-beta-border',
    text: 'text-default',
  },
  sky: {
    background: 'bg-beta-container',
    border: 'border-beta-border',
    text: 'text-default',
  },
  transparent: {
    background: 'bg-transparent',
    border: 'border-neutral-border',
    text: 'text-default',
  },
  green: {
    background: 'bg-success-container',
    border: 'border-success-border',
    text: 'text-default',
  },
  gray: {
    background: 'bg-neutral-container',
    border: 'border-neutral-border',
    text: 'text-default',
  },
  'yellow-light': {
    background: 'bg-notice-container',
    border: 'border-notice-border',
    text: 'text-default',
  },
  yellow: {
    background: 'bg-notice-container',
    border: 'border-notice-border',
    text: 'text-default',
  },
  red: {
    background: 'bg-error-container',
    border: 'border-error-border',
    text: 'text-default',
  },
};

const DEFAULT_OPTION_CLASS = STAMP_COLOR_MAP['sky'];

export default class StampComponent extends Component {
  get color() {
    if (this.args.color) {
      let color = this.args.color.toLowerCase();
      if (!STAMP_COLOR_MAP[color]) {
        assert(
          `<Pulse::Stamp>: The color \`${
            this.args.color
          }\` is invalid. Select from these valid options: ${Object.keys(STAMP_COLOR_MAP).join(
            ', ',
          )}`,
        );
      }
      return STAMP_COLOR_MAP[color];
    }
    return DEFAULT_OPTION_CLASS;
  }
}
