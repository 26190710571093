import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  HELP_CONTENT_EXPERIMENT_FF_NAME,
  SHOW_INLINE_ARTICLE_FF_NAME,
} from '@intercom/pulse/lib/temp-constants';

export default class PulseHelpContentLink extends Component {
  @service appService;
  @service intercomEventService;

  linkStyling = this.args.linkStyling || 'standalone';

  get isDisabledByExperimentalFeatureFlag() {
    return (
      this.linkStyling === 'standalone' &&
      this.appService?.app?.canUseFeature(HELP_CONTENT_EXPERIMENT_FF_NAME)
    );
  }

  @action
  handleClick(event) {
    if (this.args.handleLinkExternally) {
      event.preventDefault();
    }

    let isInlineArticleEnabled = this.appService?.app?.canUseFeature(SHOW_INLINE_ARTICLE_FF_NAME);
    if (isInlineArticleEnabled && this.args.type === 'article' && typeof Intercom !== 'undefined') {
      let match = this.args.href.match(/\/articles\/(\d+).*/); // extract ID from URL
      if (match) {
        window.Intercom('showArticle', match[1]);
        event.preventDefault();
      }
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_content_link',
      context: this.linkStyling,
      content_type: this.args.type,
      link_to: this.args.href,
    });
  }
}
